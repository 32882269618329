import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageRow from '../../atoms/PageRow/PageRow'
import t from '../../i18n/translations/translations'
import { getInstancias, getUserUZInfo, updateUserUz } from '../../store/actions'
import { setCookie, getCookie } from '../../utils/cookiesFunctions'
import './UserDataContainer.css'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/eu';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

function UserDataContainer({ data, match }) {
    const userId = window.location.href.split(`/`)[window.location.href.split(`/`).length - 1];

    const dispatch = useDispatch()

    const { pages } = data
    const { data: pageData } = pages
    const { lang } = match

    const { titulo } = pageData[0]

    setCookie('idUser', userId);

    useEffect(() => {
        const getUserInfo = (payload) => dispatch(getUserUZInfo(payload))
        const getInstances = (payload) => dispatch(getInstancias(payload))
        getUserInfo({ userId })
        getInstances()
    }, [dispatch])

    const { instances, userData, userFormState } = useSelector(
        ({ hartuz }) => ({
            userData: hartuz?.userInfo?.data,
            userFormState: hartuz?.userFormState,
            instances: hartuz?.instancesList?.data,
        })
    )

    const [email, setEmail] = useState('')
    const [census, setCensus] = useState('')
    const [birthDate, setBirthDate] = useState(null)
    const [message, setMessage] = useState('')
    const [showMessage, setShowMessage] = useState(false)
    const [typeMessage, setTypeMessage] = useState('')
    const dd = String(new Date().getDate()).padStart(2, '0');
    const mm = String(new Date().getMonth() + 1).padStart(2, '0');
    const yyyy = new Date().getFullYear();
    const currentDate = `${dd}-${mm}-${yyyy}`;
    const [instanceCensus, setInstanceCensus] = useState(null)

    useEffect(() => {
        dayjs.locale(lang);
    }, [instances]);

    useEffect(() => {
        dayjs.locale(lang); 
    }, [lang]);

    useEffect(() => {
        setEmail(userData?.email)
        setCensus(userData?.id_instance_census)
        setBirthDate(userData?.birthday)
        if (instances) {
            instances.map((option) => {
                if (option.id === userData.id_instance_census) {
                    setInstanceCensus(option.name[lang]);
                }
            })
        }

        if (!instanceCensus) {
            setMessage(t.warning_no_censado[lang]);
            setShowMessage(true);
            setTypeMessage('WARNING')
        } else {
            setShowMessage(false);
        }
        
    }, [userData, instances])

    useEffect(() => {
        if (userFormState) {
            setShowMessage(true)
            setTypeMessage(userFormState)

            if (userFormState === 'ERROR') {
                setMessage(t.error_datos_no_guardados[lang])
            } else {
                setMessage(t.success_datos_guardados[lang])
            }
        }
    }, [userFormState])

    const updateUser = (payload) => dispatch(updateUserUz(payload))

    const handleSubmit = () =>
        updateUser({
            newCensus: census,
            newEmail: email,
            newBirthDate: birthDate,
            userId,
        })

    const handleCloseMessage = () => setShowMessage(false)

    const handleDateChange = (newValue) => {
        if (newValue) {
            const formattedDate = newValue.startOf('day').format('YYYY-MM-DD')
            setBirthDate(formattedDate)
        } else {
            setBirthDate(null)
        }
    }

    return (
        <div className="userDataContainer">
            <div className="userDataContainer__content">
                <PageRow></PageRow>
                <PageRow>
                    {userData ? (
                        <div className="userDataContainer__form">
                            <h2 className="userDataContainer__form-header">
                                {t.user_info[lang]}
                            </h2>
                            {showMessage ? (
                                <div
                                    className={`alertMessage ${typeMessage.toLowerCase()}Message`}
                                >
                                    <p>{message}</p>
                                    <span
                                        className="userDataContainer__closeBtn"
                                        onClick={handleCloseMessage}
                                    >
                                        &times;
                                    </span>
                                </div>
                            ) : null}
                            <div>
                                <label htmlFor="username">{t.name[lang]}</label>
                                <input
                                    disabled
                                    type="text"
                                    name="username"
                                    id="username"
                                    value={userData.name}
                                />
                            </div>
                            <div>
                                <label htmlFor="surname1">{`${t.surname[lang]} 1`}</label>
                                <input
                                    disabled
                                    type="text"
                                    name="surname1"
                                    id="surname1"
                                    value={userData.lastname}
                                />
                            </div>
                            <div>
                                <label htmlFor="surname2">{`${t.surname[lang]} 2`}</label>
                                <input
                                    disabled
                                    type="text"
                                    name="surname2"
                                    id="surname2"
                                    value={userData.second_lastname}
                                />
                            </div>
                            <div>
                                <label htmlFor="dni">{t.dni[lang]}</label>
                                <input
                                    disabled
                                    type="text"
                                    name="dni"
                                    id="dni"
                                    value={userData.dni}
                                />
                            </div>
                            <div>
                                <label htmlFor="email">{t.email[lang]}</label>
                                <input
                                    name="email"
                                    id="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    value={email}
                                />
                            </div>
                            {instances ? (
                                <>
                                    <div>
                                        <label htmlFor="census">
                                            {t.census[lang]}
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            name="instanceCensus"
                                            id="instanceCensus"
                                            value={instanceCensus}
                                        />
                                    </div>
                                    <input
                                        disabled
                                        type="hidden"
                                        name="idPerson"
                                        id="idPerson"
                                        value={userData.id_card}
                                    />
                                    <div>
                                        <label htmlFor="birthday">
                                            {t.birthday[lang]}
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            name="birthDate"
                                            id="birthDate"
                                            value={birthDate}
                                        />
                                    </div>
                                </>
                            ) : null}
                            <div className="submitUserData">
                                <button onClick={handleSubmit} type="submit">
                                    {t.save[lang]}
                                </button>
                            </div>
                        </div>
                    ) : null}
                </PageRow>
            </div>
        </div>
    )
}

export default UserDataContainer
